import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavNewArrivals = ({
    activeSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleToggleSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 z-10 w-[32px]"
                    onClick={handleToggleSubnav}
                    data-nav="new_arrivals"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    New Arrivals
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>

            <div
                className={`subnav subnav-new-arrivals bg-[#FFFAF5] block`}
                data-subnav="new_arrivals"
            >
                <ul className="nostyle">
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.new_arrivals}>
                            Shop All New Arrivals
                        </a>
                    </li>
                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.holiday_shop}>
                            The Holiday Shop
                        </a>
                    </li> */}
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.toy_story}>
                            Disney Pixar Toy Story
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney_princesses}>
                            Disney Princesses
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.moana}>
                            Disney's Moana
                        </a>
                    </li>
                    {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney_holiday}>
                            The Disney Holiday Collection
                        </a>
                    </li> */}


                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.embroidery_shop}>
                            Embroidery Shop
                        </a>
                    </li>

                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.cozy_collection}>
                            The Cozy Collection
                        </a>
                    </li>
                </ul>
            </div>
            <div
                className="subsubnav subnav-baby-sizes bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="more_new_arrivals"
            >
                <div
                    className="menu-section-header flex items-center w-full pb-3"
                    data-subnav="more_new_arrivals"
                    onClick={handleToggleSubSubnav}
                >
                    <span className="menu-section-text pl-6 text-lg">
                        Shop By Age
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.more_new_arrivals === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-more-new-arrivals ${
                        activeSubSubnav && activeSubSubnav.more_new_arrivals
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="more_new_arrivals"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.baby_new_arrivals}>
                                Baby New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.toddler_new_arrivals}
                            >
                                Toddler New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.kids_new_arrivals}>
                                Kids New Arrivals
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a
                                className="block"
                                href={nav.maternity_new_arrivals}
                            >
                                Maternity New Arrivals
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavNewArrivals
