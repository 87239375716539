import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavBaby = ({
    activeSubnav,
    handleToggleSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role='navigation'
        >
            <div className='mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]'>
                <div
                    className='subnav-nav-back cursor-pointer p-3 relative left-2 w-[32px] z-10'
                    onClick={handleToggleSubnav}
                    data-nav='kids'
                >
                    <AngleLeft width='w-[12px]' />
                </div>
                <h2 className='text-center text-2xl flex-1 relative -left-[24px]'>
                    Kids
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className='mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3'
                >
                    <span className='close-x menu-close relative top-[5px] !font-bold'>
                        <CloseX width='w-[16px]' />
                    </span>
                </div>
            </div>
            <div
                className='menu-section-header flex items-center w-full py-4 border-t border-oat'
                data-subnav='kids_clothes'
                onClick={handleToggleSubSubnav}
            >
                <span className='menu-section-text pl-6 text-lg'>
                    Shop By Category
                </span>{' '}
                <span className='ml-auto mr-5'>
                    {activeSubSubnav &&
                    activeSubSubnav.kids_clothes === true ? (
                        <Minus width='w-[16px]' />
                    ) : (
                        <Plus width='w-[18px]' />
                    )}
                </span>
            </div>
            <div
                className={`subsubnav subsubnav-kids-clothes bg-[#FFFAF5] ${
                    activeSubSubnav && activeSubSubnav.kids_clothes
                        ? 'block'
                        : 'hidden'
                }`}
                data-subsubnav='kids_clothes'
            >
                <ul className='nostyle'>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_clothes}>
                            Shop All Kids
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_new}>
                            Kids New Arrivals
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_best_sellers}>
                            Kids Best Sellers
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_pajamas}>
                            Pajamas
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_bundles}>
                            Outfits + Sets
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_dresses_rompers}>
                            Dresses + Rompers
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_shirts}>
                            Shirts + Tops
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_pants}>
                            Pants + Leggings
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_shorts}>
                            Shorts
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_sweaters}>
                            Sweaters + Sweatshirts
                        </a>
                    </li>

                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_swimwear}>
                            Swimwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_jackets}>
                            Jackets + Outerwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.kids_underwear}>
                            Underwear
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal'>
                        <a className='block' href={nav.toys}>
                            Toys
                        </a>
                    </li>
                    <li className='pl-6 py-3 border-t border-oat font-normal bg-[#FFFAF5]'>
                        <a
                            href={nav.kids_sale}
                            className='text-[#BE202A] block'
                        >
                            Sale
                        </a>
                    </li>
                </ul>
            </div>
            <div
                className='subsubnav subnav-baby-sizes bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='kids_sizes'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='kids_sizes'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.kids_sizes === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Size
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.kids_sizes === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-kids-sizes ${
                        activeSubSubnav && activeSubSubnav.kids_sizes
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='kids_sizes'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_6}>
                                6
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_7}>
                                7
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_8}>
                                8
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_10}>
                                10
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_12}>
                                12
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_sizes_14}>
                                14
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='subsubnav subnav-kids-gender bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='kids_gender'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='kids_gender'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.kids_gender === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Gender
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.kids_gender === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-kids-gender ${
                        activeSubSubnav && activeSubSubnav.kids_gender
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='kids_gender'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_boy}>
                                Boy
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_girl}>
                                Girl
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.kids_unisex}>
                                Unisex
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className='subsubnav subnav-kids-collections bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='kids_collections'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='kids_collections'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.kids_collections === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Collection
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.kids_collections === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-kids-collections ${
                        activeSubSubnav && activeSubSubnav.kids_collections
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='kids_collections'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.back_to_school}>
                                Back To School
                            </a>
                        </li>

                        {/* <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.holiday}>
                                Holiday
                            </a>
                        </li> */}
                    </ul>
                </div>
            </div>

            <div
                className='subsubnav subnav-kids-moments bg-[#FFFAF5] py-3 border-t border-oat'
                data-subnav='kids_moments'
            >
                <div
                    className='menu-section-header flex items-center w-full py-1'
                    data-subnav='kids_moments'
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.kids_moments === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Character
                    </span>{' '}
                    <span className='ml-auto mr-5'>
                        {activeSubSubnav &&
                        activeSubSubnav.kids_moments === true ? (
                            <Minus width='w-[16px]' />
                        ) : (
                            <Plus width='w-[18px]' />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-baby-moments ${
                        activeSubSubnav && activeSubSubnav.kids_moments
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav='kids_moments'
                >
                    <ul className='nostyle'>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.collaborations}>
                                Shop All Characters
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney}>
                                Shop All Disney
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.toy_story}>
                                Disney Pixar Toy Story
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_princesses}>
                                Disney Princesses
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.moana}>
                                Disney's Moana
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.lion_king}>
                                Disney's The Lion King
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.winnie_the_pooh}>
                                Disney's Winnie The Pooh
                            </a>
                        </li>

                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_frozen}>
                                Disney's Frozen
                            </a>
                        </li>

                        {/* <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.mickey_minnie}>
                                Disney's Mickey + Minnie Groovy
                            </a>
                        </li> */}
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.disney_cars}>
                                Disney's Cars
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.eric_carle}>
                                Eric Carle
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.paris_hilton}>
                                Paris Hilton
                            </a>
                        </li>
                        <li className='pl-6 py-3 border-t border-oat font-normal'>
                            <a className='block' href={nav.barbie}>
                                Barbie
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavBaby
