import React from 'react'
import Plus from '@components/Icons/Plus.jsx'
import Minus from '@components/Icons/Minus.jsx'
import AngleLeft from '@components/Icons/AngleLeft.jsx'
import CloseX from '@components/Icons/Close-X.jsx'
import * as nav from '@data/navdata'

const SubnavCollaboration = ({
    activeSubnav,
    handleToggleSubnav,
    activeSubSubnav,
    setActiveSubSubnav,
    handleToggleSubSubnav,
    handleCloseMenu,
}) => {
    return (
        <nav
            className={`subnav-wrap w-full h-full bg-[#FFFAF5]`}
            role="navigation"
        >
            <div className="mobile-subnav-header flex items-center w-full sticky top-0 bg-[#FFFAF5]">
                <div
                    className="subnav-nav-back cursor-pointer p-3 relative left-2 w-[32px] z-10"
                    onClick={handleToggleSubnav}
                    data-nav="collaboration"
                >
                    <AngleLeft width="w-[12px]" />
                </div>
                <h2 className="text-center text-2xl flex-1 relative -left-[24px]">
                    Characters
                </h2>
                <div
                    onClick={handleCloseMenu}
                    className="mobile-menu-close flex absolute items-center justify-end text-center right-4 top-3"
                >
                    <span className="close-x menu-close relative top-[5px] !font-bold">
                        <CloseX width="w-[16px]" />
                    </span>
                </div>
            </div>
            <div
                className="menu-section-header flex items-center w-full py-4 border-t border-oat"
                data-subnav="collaboration_clothes"
                onClick={handleToggleSubSubnav}
            >
                <span className="menu-section-text pl-6 text-lg">
                    Shop By Category
                </span>{' '}
                <span className="ml-auto mr-5">
                    {activeSubSubnav &&
                    activeSubSubnav.collaboration_clothes === true ? (
                        <Minus width="w-[16px]" />
                    ) : (
                        <Plus width="w-[18px]" />
                    )}
                </span>
            </div>
            <div
                className={`subsubnav subsubnav-collaboration-clothes bg-[#FFFAF5] ${
                    activeSubSubnav && activeSubSubnav.collaboration_clothes
                        ? 'block'
                        : 'hidden'
                }`}
                data-subsubnav="collaboration_clothes"
            >
                <ul className="nostyle">
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collaborations}>
                            Shop All Characters
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.disney}>
                            Shop All Disney
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_pajamas}>
                            Pajamas
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_dresses}>
                            Dresses
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_outfits}>
                            Outfits
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_shirts}>
                            Shirts
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_sweaters}>
                            Sweaters + Jackets
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_one_pieces}>
                            One-Pieces + Bodysuits
                        </a>
                    </li>
                    <li className="pl-6 py-3 border-t border-oat font-normal">
                        <a className="block" href={nav.collab_underwear}>
                            Underwear
                        </a>
                    </li>
                </ul>
            </div>
            <div
                className="subsubnav subnav-collaboration-characters bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="collaboration_characters"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="collaboration_characters"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.collaboration_characters === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Character
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.collaboration_characters === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-collaboration-characters ${
                        activeSubSubnav &&
                        activeSubSubnav.collaboration_characters
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="collaboration_characters"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.moana}>
                                Disney's Moana
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.lion_king}>
                                Disney's The Lion King
                            </a>
                        </li>

                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.winnie_the_pooh}>
                                Disney's Winnie The Pooh
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_princesses}>
                                Disney Princesses
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_frozen}>
                                Disney's Frozen
                            </a>
                        </li>

                        {/* <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.mickey_minnie}>
                                Disney's Mickey + Minnie Groovy
                            </a>
                        </li> */}
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.disney_cars}>
                                Disney's Cars
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.eric_carle}>
                                Eric Carle
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.paris_hilton}>
                                Paris Hilton
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.barbie}>
                                Barbie
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-collaboration-age bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="collaboration_age"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="collaboration_age"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.collaboration_age === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Age
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.collaboration_age === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-collaboration-age ${
                        activeSubSubnav && activeSubSubnav.collaboration_age
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="collaboration_age"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_baby}>
                                Baby
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_toddler}>
                                Toddler
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_kids}>
                                Kids
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_adults}>
                                Adults
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className="subsubnav subnav-collaboration-gender bg-[#FFFAF5] py-3 border-t border-oat"
                data-subnav="collaboration_gender"
            >
                <div
                    className="menu-section-header flex items-center w-full py-1"
                    data-subnav="collaboration_gender"
                    onClick={handleToggleSubSubnav}
                >
                    <span
                        className={`menu-section-text pl-6 text-lg ${
                            activeSubSubnav &&
                            activeSubSubnav.collaboration_gender === true
                                ? 'pb-3'
                                : ''
                        }`}
                    >
                        Shop By Gender
                    </span>{' '}
                    <span className="ml-auto mr-5">
                        {activeSubSubnav &&
                        activeSubSubnav.collaboration_gender === true ? (
                            <Minus width="w-[16px]" />
                        ) : (
                            <Plus width="w-[18px]" />
                        )}
                    </span>
                </div>
                <div
                    className={`subsubnav subsubnav-collaboration-gender ${
                        activeSubSubnav && activeSubSubnav.collaboration_gender
                            ? 'block'
                            : 'hidden'
                    }`}
                    data-subsubnav="collaboration_gender"
                >
                    <ul className="nostyle">
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_boy}>
                                Boy
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_girl}>
                                Girl
                            </a>
                        </li>
                        <li className="pl-6 py-3 border-t border-oat font-normal">
                            <a className="block" href={nav.collab_unisex}>
                                Unisex
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default SubnavCollaboration
